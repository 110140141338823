import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollTrigger);
class ScrollTriggered {
    constructor() {
        this.initScrollTriggered();
    }
    mobileCheck(){
        if(window.matchMedia('(min-width: 600px)').matches && window.matchMedia('(min-height: 600px)').matches){
            return false;
        }else{
            return true;
        }
    }
    initScrollTriggered(){
        const triggeredSections = Util.$('.js-scroll-triggered');
        const docBody = Util.$('body')[0];
        const scrollTransition = (el) => {
            let thisScrollTransition = el;
            let pane1 = Util.$('.js-scroll-transition__pane1', thisScrollTransition);
            let pane2 = Util.$('.js-scroll-transition__pane2', thisScrollTransition);
            let screen =  Util.$('.js-scroll-transition__pane2-screen', thisScrollTransition);
            thisScrollTransition.classList.add('-active');
            gsap.to( screen, {
                //xPercent: theXPercent + 100,
                opacity: 0,
                ease: "none", // <-- IMPORTANT!
                scrollTrigger: {
                    trigger: pane1,
                    pin: pane2,
                    scrub: 0.1,
                    pinSpacing: true,
                    immediateRender: false,
                    //markers: true,
                    //snap: directionalSnap(1 / (sections.length - 1)),
                    end: "bottom top"
                }
            });
            
        }
        const horzGallery = (el) => {
            let thisGallery = el;
            let width = window.innerWidth * .1725;
            let gap = 98;
            let wrapper = Util.$('.js-horz-img-gallery__wrapper', el);
            let items = Util.$('.js-horz-img-gallery__img', wrapper[0]);
            let watchingResize = false;
            let isActive = false;
            const activateGallery = (thisGallery) =>{
                thisGallery.classList.add('-active');
                items.forEach(el => {
                    let heightCalc = window.innerHeight * .65;
                    let thisHeight;
                    let thisWidth;
                    if(heightCalc < 600){
                        thisHeight =  heightCalc;
                    }else{
                        thisHeight = 600;
                    }
                    if(el.classList.contains('landscape')){
                        thisWidth = thisHeight * 1.42;
                    }else{
                        thisWidth = thisHeight * .6;
                    }
                    width = width + thisWidth + gap;
                });
                // TODO - Consider if this is the best way to set the timeline
                let theEnd = "+=" + (width - (window.innerWidth * .815));
                //el.style.paddingBottom = (width - (window.innerWidth * .815)) + 'px';
                wrapper[0].style.width = width + 'px';
                let thisX = (width - (window.innerWidth * .815)) * -1;
                //container[0].style.paddingBottom = (width - (window.innerWidth * .815)) + 'px';
                let thisHorzGallery = gsap.to(wrapper, {
                    //xPercent: theXPercent + 100,
                    x: thisX,
                    ease: "none", // <-- IMPORTANT!
                    scrollTrigger: {
                    trigger: el,
                    pin: true,
                    scrub: 0.1,
                    //markers: true,
                    pinSpacing: true,
                    //snap: directionalSnap(1 / (sections.length - 1)),
                    end: theEnd
                    }
                });
                if(!watchingResize){
                    watchingResize = true;
                    resizeBehavior(isActive, thisHorzGallery);
                }
            }
            const resizeBehavior = (isActive, thisTween) =>{
                let ticking = false;
                const update = () => {
                    ticking = false;
                }
        
                const onResize = (ev) => {
                    // console.log('RESIZE');
                    // if(!this.mobileCheck() && isActive){
                    //     console.log('ALREADY ACTIVE');
                    // }else if(!this.mobileCheck() && !isActive){
                    //     console.log('ACTIVE IT ON RESIZE');
                    //     watchingResize = true;
                    //     activateGallery(thisGallery);
                    // }else if(this.mobileCheck() && isActive){
                    //     console.log('UNDO IT');
                    //     console.log(thisTween);
                    //     thisTween.scrollTrigger.disable();
                    //     thisGallery.classList.remove('-active');
                    // }
                    requestTick();
                }
        
                const requestTick = () => {
                    if(!ticking) {
                        requestAnimationFrame(update);
                    }
                    ticking = true;
                }
        
                window.addEventListener('resize', onResize, false);
            }

            if(!this.mobileCheck()){
                isActive = true;
                activateGallery(thisGallery);
            }else{
                watchingResize = true;
                resizeBehavior(isActive);
            }
            
        }
        const imageInImage = (el) => {
            let thisTrigger =  el;
            let thisEnd =  Util.$('.js-image-in-image__media-inner', el);
            let thisImageInImage = gsap.timeline({
                scrollTrigger: {
                  trigger: thisTrigger,
                  start: "center center",
                  end: "bottom center",
                  scrub: 0.1,
                  anticipatePin: 1,
                  pin: true
                }
              })
            .from(thisEnd, { y: innerHeight });
            document.addEventListener('lazyloaded', function(ev){
                thisImageInImage.scrollTrigger.refresh();
            });
        }

        const bgAnimationTextChange = (el) => {
            const content = Util.$('.js-text-bg-animtion__group', el)[0];
            // const topCopy = Util.$('.js-text-bg-animation__top', el)[0];
            // const bottomCopy = Util.$('.js-text-bg-animation__bottom', el)[0];
            ScrollTrigger.create({
                trigger: el,
                start: "top top",
                //endTrigger: "#otherID",
                end: "bottom bottom",
                pin: content,
                //markers: true,
                onToggle: (self) => {
                    // console.log('ON TOGGLE');
                    // console.log(self.isActive);
                    // console.log(self.progress);
                    
                },
                onUpdate: (self) => {
                    // console.log('ON UPDATE');
                    // console.log(self.progress);
                    if(self.progress > 0.4){
                        el.classList.add('-switched')
                    }
                },
                onLeaveBack: (self) => el.classList.remove('-switched'),
                //onLeave: (self) => el.classList.remove('-switched'),
                //onLeaveBack: (self) => el.classList.remove('-switched'),
            });
            
        }
        const horzScroller = (el) => {
            const scrollerLine1 = Util.$('.js-horz-scroller__top', el)[0];
            const scrollerLine2 = Util.$('.js-horz-scroller__bottom', el)[0];
            const scrollerTopGroups = Util.$('.js-horz-scroller__group', scrollerLine1);
            const scrollerBottomGroups = Util.$('.js-horz-scroller__group', scrollerLine2);
            const windowWidth = window.innerWidth;
            let currentScroll = 0;
            let isScrollingDown = true;
            let startPosTop = -50;
            let startPosBottom = 0;
            //console.log('window width: ' + windowWidth);
            if(windowWidth > 600 && windowWidth < 1500){
                startPosBottom = -200;
            }else if(windowWidth > 1500){
                startPosTop = 0;
                startPosBottom = -150;
            }else{
                startPosBottom = -280;
            }
            let tween1 = gsap.to(scrollerTopGroups, 
                {   xPercent: -100, 
                    repeat: -1, 
                    duration: 17,
                    scrollTrigger:{
                        trigger: el,
                        start: "top top+=50%",
                        onUpdate: (self) => {
                            let thisVelocity = Math.abs(self.getVelocity()) * .003;
                            gsap.to(tween1, {
                                timeScale: thisVelocity
                            });
                            window.setTimeout(function(){
                                gsap.to(tween1, {
                                    timeScale: 1
                                });
                            }, 200);
                        }
                    }, 
                    ease: "linear",}).totalProgress(0.5);
            let tween2 = gsap.to(scrollerBottomGroups, 
                {   xPercent: 100, 
                    repeat: -1, 
                    duration: 18, 
                    scrollTrigger:{
                        trigger: el,
                        start: "top top+=45%",
                        //markers: true,
                        onUpdate: (self) => {
                            let thisVelocity = Math.abs(self.getVelocity()) * .003;
                            gsap.to(tween2, {
                                timeScale: thisVelocity
                            });
                            window.setTimeout(function(){
                                gsap.to(tween2, {
                                    timeScale: 1
                                });
                            }, 200);
                        }
                    },
                    ease: "linear"}).totalProgress(0.5);

            gsap.set(scrollerLine1 , {xPercent: startPosTop});
            gsap.set(scrollerLine2 , {xPercent: startPosBottom});
        }
        const maskedImage = (el) => {
            const media = Util.$('.js-masked-image__media', el)[0];
                gsap.to( media, {
                    //xPercent: theXPercent + 100,
                    maskSize: '500vmin',
                    ease: "none", // <-- IMPORTANT!
                    scrollTrigger: {
                        trigger: el,
                        start: "top top",
                        //endTrigger: "#otherID",
                        end: "bottom bottom",
                        scrub: true,
                        pin: media,
                        //markers: true,
                        onToggle: (self) => el.classList.add('-ended'),
                        onLeave: (self) => el.classList.remove('-switched'),
                        onLeaveBack: (self) => el.classList.remove('-switched'),
                    }
                });
        }

        // const snowTrackerTop = (el) => {
        //     const topContent = Util.$('.js-ski-landing__upper')[0];
        //     const tracker = Util.$('.js-snow-tracker')[0];
        //     const snowTrackerGraphic = Util.$('.js-snow-tracker__graphic-svg')[0];
        //     const snowTrackerNum = Util.$('.js-snow-tracker__val')[0];
        //     const scaleVolume = +tracker.getAttribute('data-snow_total');
        //     let width = window.innerWidth;
        //     let scale = 1 / (width / 1400);
        //     let value = 0;
        //     gsap.to(snowTrackerGraphic, {
        //         scrollTrigger: {
        //             trigger: el,
        //             start: "bottom 80%", 
        //             endTrigger: topContent,
        //             end: "bottom bottom",
        //             //pin: tracker,
        //             pinSpacing: false,
        //             scrub: false,
        //             //markers: true,
        //             onToggle: (self) => {
        //                 topContent.classList.add('-tracking');
        //             },
        //             onLeave: (self) => {
        //                 topContent.classList.remove('-tracking');
        //                 topContent.classList.add('-tracker-stuck');
        //             },
        //             onLeaveBack: (self) => {
        //                 topContent.classList.remove('-tracking');
        //                 topContent.classList.remove('-tracker-stuck');
        //             },
                    
        //         }, // start the animation when ".box" enters the viewport (once)
        //         scaleY: scale,
        //         duration: 12,
        //         delay: 1,
        //         onUpdate: (self) => {
        //             let thisIncrement = Math.round(scaleVolume * (value / 500));
        //             let thisUpdate;
        //             if(scaleVolume > thisIncrement){
        //                 thisUpdate = thisIncrement;
        //             }else{
        //                 thisUpdate = scaleVolume;
        //             }
        //             snowTrackerNum.textContent = thisUpdate;
        //             value++;
        //         },
        //       });
        // }
        // const snowTrackerBottom = (el) => {
        //     const smallTrackerBtn = Util.$('.js-snow-tracker__small-btn')[0];
        //     if(el && smallTrackerBtn){
        //         ScrollTrigger.create({
        //             trigger: el,
        //             start: "top 50%",
        //             //endTrigger: "#otherID",
        //             end: "bottom top",
        //             //markers: true,
        //             onToggle: (self) => smallTrackerBtn.classList.add('-active'),
        //             //onLeave: (self) => smallTrackerBtn.classList.remove('-active'),
        //             onLeaveBack: (self) => smallTrackerBtn.classList.remove('-active'),
        //         });
        //     }
        // }
        if(triggeredSections.length > 0){
            // Triggered sections must be activated in the order they appear in the DOM
            // Activating scrolling things for ski landing after everything loads
            if(docBody.classList.contains('ski-landing') || docBody.classList.contains('s-utah-landing')){
                window.addEventListener('skilandingload', function(){
                    //console.log('ski landing load bind');
                    triggeredSections.forEach(el =>{
                        if(el.classList.contains('js-scroll-transition')){
                            scrollTransition(el);
                        }
                        if(el.classList.contains('js-horz-img-gallery')){
                            horzGallery(el);
                        }
                        if(el.classList.contains('js-image-in-image')){
                            imageInImage(el);
                        }
                        if(el.classList.contains('js-text-bg-animation')){
                            bgAnimationTextChange(el);
                        }
                        if(el.classList.contains('js-masked-image')){
                            maskedImage(el);
                        }
                        if(el.classList.contains('js-hero-ski')){
                           // snowTrackerTop(el);
                        }
                        if(el.classList.contains('js-ski-landing__lower')){
                           // snowTrackerBottom(el);
                        }
                        if(el.classList.contains('js-horz-scroller')){
                            horzScroller(el);
                        }
                    });
                })
            }else{
                const loadEvent = new Event('scrolltriggerready');
                window.addEventListener("load", (event) => {
                    
                    console.log('window load');
                    setTimeout(() => {
                        window.dispatchEvent(loadEvent);
                        console.log('delayed event');
                      }, 500);
                });
                window.addEventListener('scrolltriggerready', function(){
                    triggeredSections.forEach(el =>{
                        if(el.classList.contains('js-scroll-transition')){
                            scrollTransition(el);
                        }
                        if(el.classList.contains('js-horz-img-gallery')){
                            horzGallery(el);
                        }
                        if(el.classList.contains('js-image-in-image')){
                            imageInImage(el);
                        }
                        if(el.classList.contains('js-text-bg-animation')){
                            bgAnimationTextChange(el);
                        }
                        if(el.classList.contains('js-masked-image')){
                            maskedImage(el);
                        }
                        if(el.classList.contains('js-hero-ski')){
                            snowTrackerTop(el);
                        }
                        if(el.classList.contains('js-ski-landing__lower')){
                            snowTrackerBottom(el);
                        }
                    });
                });
            }
        }
    }
}
export default ScrollTriggered;