import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollTrigger);
class HorzScroller {
    constructor() {
        this.initHorzScroller();
    }
    settings = {
        desktop: '1024px'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initHorzScroller(){
        // const scroller = Util.$('.js-horz-scroller');
        // //TODO: Check for it
        // if(scroller.length > 0){
        //     scroller.forEach(function(el){
        //         const scrollerLine1 = Util.$('.js-horz-scroller__top', el)[0];
        //         const scrollerLine2 = Util.$('.js-horz-scroller__bottom', el)[0];
        //         const scrollerTopGroups = Util.$('.js-horz-scroller__group', scrollerLine1);
        //         const scrollerBottomGroups = Util.$('.js-horz-scroller__group', scrollerLine2);
        //         let currentScroll = 0;
        //         let isScrollingDown = true;
    
        //         let tween1 = gsap.to(scrollerTopGroups, 
        //             {   xPercent: -100, 
        //                 repeat: -1, 
        //                 duration: 20,
        //                 scrollTrigger:{
        //                     trigger: el,
        //                     start: "top top+=50%",
        //                     onUpdate: (self) => {
        //                         let thisVelocity = Math.abs(self.getVelocity()) * .025;
        //                         gsap.to(tween1, {
        //                             timeScale: thisVelocity
        //                         });
        //                         window.setTimeout(function(){
        //                             gsap.to(tween1, {
        //                                 timeScale: 1
        //                             });
        //                         }, 200);
        //                     }
        //                 }, 
        //                 ease: "linear",}).totalProgress(0.5);
        //         let tween2 = gsap.to(scrollerBottomGroups, 
        //             {   xPercent: 100, 
        //                 repeat: -1, 
        //                 duration: 25, 
        //                 scrollTrigger:{
        //                     trigger: el,
        //                     start: "top top+=45%",
        //                     onUpdate: (self) => {
        //                         let thisVelocity = Math.abs(self.getVelocity()) * .025;
        //                         gsap.to(tween2, {
        //                             timeScale: thisVelocity
        //                         });
        //                         window.setTimeout(function(){
        //                             gsap.to(tween2, {
        //                                 timeScale: 1
        //                             });
        //                         }, 200);
        //                     }
        //                 },
        //                 ease: "linear"}).totalProgress(0.5);
    
        //         gsap.set(scrollerLine1 , {xPercent: -50});
        //         gsap.set(scrollerLine2 , {xPercent: -300});
    
    
                
        //     });
        // }
        
    }
}
export default HorzScroller;