import Util from "../utils/util.js";
import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);
class IGTV {
    constructor() {
        this.initIGTV();
    }
    initIGTV(){
        let IGTVgroup = Util.$('.js-igtv');
        //console.log(IGTVgroup);
        IGTVgroup.forEach(element => {
            
            let thisCarousel = Util.$('.js-igtv__slide-container', element)[0];
            let carouselNavPrev = Util.$('.js-igtv-carousel-prev', element)[0];
            let carouselNavNext = Util.$('.js-igtv-carousel-next', element)[0];
            let items = Util.$('.js-igtv__item', element);
            if(items.length > 3){
                if(items.length == 4){
                    console.log('4');
                    element.classList.add('-four');
                }else{
                    element.classList.add('-multiple');
                }
                const carousel = new Swiper(thisCarousel, {
                    //slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 4,
                    slidesPerView: "auto",
                    centeredSlides: true,
                    navigation: {
                        nextEl:  carouselNavNext,
                        prevEl: carouselNavPrev,
                    },
                    // Responsive breakpoints
                    pagination: {
                        el: '.js-snow-report__pagination',
                    },
                    breakpoints: {
                        636: {
                          slidesPerView: 2,
                          slidesPerGroup: 2,
                          spaceBetween: 30,
                          centeredSlides: false
                        },
                        1056: {
                          slidesPerView: 3,
                          slidesPerGroup: 3,
                          spaceBetween: 30,
                          centeredSlides: false
                        },
                        1168: {
                          slidesPerView: 4,
                          slidesPerGroup: 4,
                          spaceBetween: 20,
                          centeredSlides: false
                        },
                    }
                });
            }else if(items.length == 3){
                element.classList.add('-three');
                const carousel = new Swiper(thisCarousel, {
                    //slidesPerView: 'auto',
                    slidesPerGroup: 1,
                    spaceBetween: 4,
                    slidesPerView: "auto",
                    centeredSlides: true,
                    navigation: {
                        nextEl:  carouselNavNext,
                        prevEl: carouselNavPrev,
                    },
                    // Responsive breakpoints
                    pagination: {
                        el: '.js-snow-report__pagination',
                    },
                    breakpoints: {
                        636: {
                          slidesPerView: 2,
                          slidesPerGroup: 2,
                          spaceBetween: 30,
                          centeredSlides: false
                        },
                        1056: {
                          slidesPerView: 3,
                          slidesPerGroup: 3,
                          spaceBetween: 30,
                          centeredSlides: false
                        },
                        1168: {
                          slidesPerView: 3,
                          slidesPerGroup: 3,
                          spaceBetween: 30,
                          centeredSlides: false
                        },
                    }
                });
            }
            
        });


    }
}
export default IGTV;