import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Hammer from "hammerjs";
import Util from "../utils/util.js";

gsap.registerPlugin(CSSPlugin);
class ImageStripModal {
    constructor() {
        this.initModalWindow();
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initModalWindow(){
        let galleryGroup = Util.$('.js-image-strip');
        let mw = this;

        if(galleryGroup.length > 0){
            galleryGroup.forEach(function(el){
                let modal = Util.$('.js-drawer')[0];
                mw.bindModalGallery(el, modal);
            });
        }
    }
    bindModalGallery(galleryGroup, modal){
        // Loop through links
        // Add data-gallery_order to each link
        // figure out prev and next in gallery
        // bind initial click to add info to drawer
        // function for drawer update

        let galleryLinks = Util.$('.js-image-strip__item-link', galleryGroup);
        let spot = 0;
        let modalClose = Util.$('.js-drawer__nav-back')[0];
        let loader = '<div class="lds-ripple"><div></div><div></div></div>';
        let page = Util.$('html');
        let mw = this;
        let galleryItems = mw.setGalleryItems(galleryLinks);
        let galleryNav = Util.$('.js-drawer__nav-paging')[0];
        galleryLinks.forEach(function(el){
            el.setAttribute('data-gallery_spot', spot);
            spot = spot + 1;
            el.addEventListener("click", function(ev){
                ev.preventDefault();
                let num = el.getAttribute('data-gallery_spot');
                mw.showImgModal(modal, galleryItems[num], galleryLinks, galleryItems, num);
            });
        });
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                let drawerWrapper = Util.$('.js-drawer__wrapper', modal);
                page[0].classList.remove('drawer-open');
                gsap.to(modal, {duration: 1, x: "100%", onComplete: function(){
                    drawerWrapper[0].innerHTML = loader;
                    galleryNav.innerHTML = "";
                }});
            }
        };
        modalClose.addEventListener('click', function(){
            page[0].classList.remove('drawer-open');
            let drawerWrapper = Util.$('.js-drawer__wrapper', modal);
            gsap.to(modal, {duration: 1, x: "100%", onComplete: function(){
                drawerWrapper[0].innerHTML = loader;
                galleryNav.innerHTML = "";
            }});
        })
    }
    setGalleryItems(galleryLinks){
        let galleryItems = [];
        let spot = 0;
        let mw = this;
        galleryLinks.forEach(function(el){
            el.setAttribute('data-gallery_spot', spot);
            let itemInfo = {};
            itemInfo.itemUrl = el.getAttribute('href');
            itemInfo.itemTitle = el.getAttribute('data-img_title');
            itemInfo.itemDesc = el.getAttribute('data-img_desc');
            itemInfo.itemAttrPrimary = el.getAttribute('data-img_attr_primary');
            itemInfo.itemAttrSecondary = el.getAttribute('data-img_attr_secondary');
            galleryItems[spot] = itemInfo;
            spot = spot + 1;
        });
        return galleryItems;
    }
    showImgModal(modal, info, galleryLinks, galleryItems, num){
            let drawerWrapper = Util.$('.js-drawer__wrapper', modal);
            let mw = this;
            let page = Util.$('html');
            let drawerCta = Util.$('.js-drawer__nav-cta-btn', modal);

            drawerCta[0].classList.add('hidden');
            gsap.to(modal, {
                duration: 1, x: "0%", onComplete: function () {
                    page[0].classList.add('drawer-open');
                    let modalImg = new Image();
                    modalImg.addEventListener('load', function(){
                        drawerWrapper[0].innerHTML = mw.buildContent(info);
                        mw.buildNav(modal, info, galleryLinks, galleryItems, num);
                    });
                    modalImg.src = info.itemUrl;
                    page[0].classList.add('drawer-open');
                    //TODO: ADD NAVIGATION
                }
            });
    }
    swapImage(info, modal, num){
        let drawerWrapper = Util.$('.js-drawer__wrapper', modal);
        let mw = this;
        let newContent = mw.buildContent(info);
        

        drawerWrapper[0].innerHTML = newContent;
    }
    buildContent(info){
        let content = "";
        let contentStart = '<div class="drawer__img-container"><div class="drawer__img-container-wrapper">';
        let contentEnd = '</div></div>';
        content = contentStart + '<img class="drawer__img" src="'+ info.itemUrl + '"/>';
        if(info.itemTitle || info.itemDesc || info.itemAttrPrimary || info.itemAttrSecondary){
            content = content + '<div class="drawer__meta">';
            if(info.itemTitle){
                content = content+'<p class="drawer__title">' + info.itemTitle + '</p>';
            }
            if(info.itemDesc){
                content = content+'<p class="drawer__desc">' + info.itemDesc + '</p>';
            }
            if(info.itemAttrPrimary){
                content = content+'<p class="drawer__attr-primary">' + info.itemAttrPrimary + '</p>';
            }
            if(info.itemAttrSecondary){
                content = content+'<p class="drawer__attr-secondary">' + info.itemAttrSecondary + '</p>';
            }
            content = content + '</div>'
        }
        content = content + contentEnd;
        return content;
    }
    buildNav(modal, info, galleryLinks, galleryItems, num, beenBuilt){
        let navMarkup = '';
        let mw = this;
        let navMarkupStart = '<div class="drawer__pagination"><ul class="drawer__pagination-list">';
        let navMarkupEnd = '</ul></div>';
        let spot = 0;
        let galleryNav = Util.$('.js-drawer__nav-paging')[0];
        let navControls = Util.$('.js-drawer__nav-control', modal);
        navMarkup = navMarkup + navMarkupStart;
        galleryItems.forEach(function(){
            navMarkup = navMarkup + '<li class="drawer__pagination-list-item"><a href="' + spot + '" class="drawer__pagination-list-item-link js-drawer__pagination-list-item-link" >'+ spot + '</a></li>';
            spot = spot + 1; 
        });
        navMarkup = navMarkup + navMarkupEnd;
        galleryNav.innerHTML = navMarkup;

        let navLinks = Util.$('.js-drawer__pagination-list-item-link', modal);
        navLinks[num].classList.add('-active');
        // Load prev and next images
        this.loadPrevNextImgs(galleryItems, num);
        // add arrows and nav to top of modal
        // checking to see if they've been bound
        if(!navControls[0].classList.contains('-active')){
            let thisHammer = new Hammer(modal);
            thisHammer.on('swipe', function(ev){
                let navLinks = Util.$('.js-drawer__pagination-list-item-link', modal);
                let activeLink = Util.$('.js-drawer__pagination-list-item-link.-active', modal)[0];
                let activeNum = parseInt(activeLink.getAttribute('href'), 10);
                if(ev.deltaX > 0 ){
                    activeNum = activeNum - 1;
                }else{
                    activeNum = activeNum + 1;
                }
                if(activeNum >= 0 && activeNum <= (navLinks.length + 1)){
                    navLinks[activeNum].click();
                }                    
            })
            navControls.forEach(function(el){
                el.classList.add('-active');
                el.addEventListener('click', function(ev){
                    ev.preventDefault();
                    let navLinks = Util.$('.js-drawer__pagination-list-item-link', modal);
                    let activeLink = Util.$('.js-drawer__pagination-list-item-link.-active', modal)[0];
                    let activeNum = parseInt(activeLink.getAttribute('href'), 10);
                    if(el.classList.contains('-prev')){
                        activeNum = activeNum - 1;
                    }else{
                        activeNum = activeNum + 1;
                    }
                    navLinks[activeNum].click();
                });
                
            });
        }
        
        // Bind events
        navLinks.forEach(function(el){
            el.addEventListener('click', function(ev){
                ev.preventDefault();
                if(el.classList.contains('-active')){
                    return false;
                }else{
                    let currentLink = Util.$('.js-drawer__pagination-list-item-link.-active', modal)[0];
                    let newNum = parseInt(el.getAttribute('href'), 10);
                    let newImgInfo = galleryItems[newNum];
                    el.classList.add('-active');
                    currentLink.classList.remove('-active');
                    mw.swapImage(newImgInfo, modal, newNum);
                    mw.loadPrevNextImgs(galleryItems, newNum);
                }
            });
        });
    }
    loadPrevNextImgs(galleryItems, num){
        let prevImg = new Image();
        let nextImg = new Image();
        let nextNum = parseInt(num, 10) + 1;
        let prevNum = parseInt(num, 10) - 1;
        if(num < galleryItems.length){
            nextImg.src = galleryItems[nextNum]["itemUrl"];
        }
        if(num > 0){
            prevImg.src = galleryItems[prevNum]["itemUrl"];
        }
    }
    
    showModal(modal){
        gsap.to(modal, {x: 0});
    }
    hideModal(modal){
        gsap.to(modal, {x: '100%'});
    }
}
export default ImageStripModal;