import Util from "../utils/util.js";
import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);
class SortableCardCarousel {
    constructor() {
        this.initCardCarousel();
    }
    initCardCarousel(){
        const carousel = Util.$('.js-sortable-card-group__slider');
        let icc = this;
        if(carousel.length > 0){
            carousel.forEach(function(el, i){
                //Adding blank card at end of carousel for display issues
                let cardMarkup = document.createElement('li');
                const slides = Util.$('.js-sortable-img-carousel-card', el);
                const slideImgs = Util.$('.lazyload', el);
                let count = slides.length;
                let classCount = "-slide-count-" + count;
                el.classList.add(classCount);
                cardMarkup.classList.add('swiper-slide');
                let list = Util.$('.swiper-wrapper', el);
                list[0].appendChild(cardMarkup);
                //console.log(slideImgs);
                //Look for hero buttons
                slideImgs.forEach(function(el){
                    lazySizes.loader.unveil(el);
                });
                const heroSortBtns = Util.$('.js-hero-ski__filter-btn');
                
                // INITIALIZE SWIPER
                const swiper = new Swiper( el, {
                    //slidesPerView: 'auto',
                    slidesPerView: 2.25,
                    slidesPerGroup: 2,
                    spaceBetween: 15,
                    on: {
                        init: function () {
                          //console.log('swiper initialized');
                        },
                      },
                    // Responsive breakpoints
                    breakpoints: {
                        // when window width is >= 320px
                        476: {
                            slidesPerView: 2.25,
                            slidesPerGroup: 2,
                            spaceBetween: 25,
                        },
                        // when window width is >= 480px
                        768: {
                            slidesPerView: 3,
                            slidesPerGroup: 2,
                            spaceBetween: 35,
                        },
                        // when window width is >= 480px
                        1068: {
                            slidesPerView: 4.25,
                            slidesPerGroup: 3,
                            spaceBetween: 45,
                        },
                        // when window width is >= 1480px
                        1480: {
                            slidesPerView: 4.87,
                            slidesPerGroup: 5,
                            spaceBetween: 45,
                        },
                        1680: {
                            slidesPerView: 4.87,
                            slidesPerGroup: 5,
                            spaceBetween: 45,
                        }
                    },
                    navigation: {
                        nextEl: '.js-carousel-next',
                        prevEl: '.js-carousel-prev',
                    },
                });
                // Check for sort conditions
                if(slides[0].hasAttribute('data-sort') && heroSortBtns.length > 0 && i === 0){
                    icc.setupSort(slides, heroSortBtns, swiper);
                }
            });
            
            

        }
    }
    setupSort(slides, buttons, carousel){
        const lastSlide = carousel.slides[carousel.slides.length - 1];
        buttons.forEach(function(el){
            el.addEventListener('click', function(ev){
                ev.preventDefault();
                const filterTerm = el.getAttribute('data-filter').toLowerCase();
                carousel.removeSlide(slides.length);
                let theseSlides = carousel.slides;
                let matchCount = 0;
                let slidesToSort = [];
                let slidePositions = [];
                theseSlides.forEach(function(el, i){
                    let getSorted = false;
                    const sortOptionString = el.getAttribute('data-sort');
                    const sortArray = sortOptionString.split(',');
                    sortArray.forEach(function(el){
                        if(filterTerm === el){
                            getSorted = true;
                            matchCount++;
                        }
                    });
                    if(!getSorted){
                        slidesToSort.push(el);
                        slidePositions.push(i);
                    }
                });
                carousel.removeSlide(slidePositions);
                carousel.appendSlide(slidesToSort);
                carousel.appendSlide(lastSlide);
            });
        });
    }
}
export default SortableCardCarousel;