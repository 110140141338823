import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollTrigger);
class MaskedImage {
    constructor() {
        this.initMaskedImage();
    }
    settings = {
        desktop: '1024px'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initMaskedImage(){
        const maskedImage = Util.$('.js-masked-image');
        if(maskedImage.length > 0){
            maskedImage.forEach(function(el){

                //console.log('MASKED');
                
            });
        }
        
        

       
        
    }
}
export default MaskedImage;