import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class Header {
    constructor() {
        this.initHeader();
    }
    settings = {
        desktop: '1024px'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initHeader(){
        let header = Util.$('.js-header');
        let mobileButton = Util.$('.js-header__menu-btn', header[0]);
        let mainNav = Util.$('.js-header__navigation' , header[0]);
        let dropdowns = Util.$('.js-header__main-nav-list-item', header[0]);
        let h = this;
        if(header.length > 0){
            this.scrollBehavior(header[0]);
            this.bindMenuToggle(mainNav[0], mobileButton[0], header[0], true);
            dropdowns.forEach( (el) => {
                let dropdown = Util.$('.js-header__main-nav-dropdown', el);
                if(dropdown.length > 0){
                    this.bindDropdown(dropdown[0], el, true, header[0]);
                    el.classList.add('-has-dropdown');
                    let mobileToggle = Util.$('.js-header__main-nav-item-mobile-toggle', el);
                    if(mobileToggle.length > 0){
                        this.bindMenuToggle(dropdown[0], mobileToggle[0], el, false);
                    }
                    // FIX MOBILE HASH LINKS
                    let links = Util.$('a[href*="#"]', el);
                    let closeBtn = Util.$('.js-header__dropdown-close-btn', el)[0];
                    
                    links.forEach((el) => {
                        if(!el.classList.contains('js-header__dropdown-close-btn')){
                            el.addEventListener('click', function(ev){
                                if(!h.mobileCheck()){
                                    closeBtn.click();
                                }else{
                                    mobileButton[0].click();
                                }
                            });
                        }
                    });
                }else{
                    this.bindDropdown(dropdown[0], el, false);
                }
            });
        }
        
    }
    bindMenuToggle(dropdown, item, parent, isMain, header ){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let h = this;
        item.addEventListener('click', function(ev){
            ev.preventDefault();
            let active = item.classList.contains('-active');
            let body = document.getElementsByTagName( 'body' )[0];
            if(active){
                item.classList.remove('-active');
                dropdown.classList.remove('-active');
                if(isMain){
                    body.classList.remove('menu-open');
                    //parent.classList.remove('-menu-active');
                    h.hideDropdown(dropdown);
                }else{
                    parent.classList.remove('-active');
                    h.hideDropdown(dropdown);
                }
            }else{
                item.classList.add('-active');
                dropdown.classList.add('-active');
                if(isMain){
                    body.classList.add('menu-open');

                    //parent.classList.add('-menu-active');
                }else{
                    let dropdownContent = Util.$('.js-header__main-nav-dropdown-wrapper', dropdown)[0];
                    let height = dropdownContent.offsetHeight;
                    parent.classList.add('-active');
                    h.showDropdown(dropdown, height);
                }
            }
                      
        });
    }
    bindDropdown(dropdown, item, hasDropdown, header){
        let h = this;
        item.addEventListener('mouseenter', function(){
            item.classList.add('-hovered');
            if(hasDropdown){
                if(!h.mobileCheck()){
                    let dropdownContent = Util.$('.js-header__main-nav-dropdown-wrapper', dropdown)[0];
                    let height = dropdownContent.offsetHeight;
        
                    dropdown.classList.add('-hovered'); 
                    header.classList.add('-menu-active');
                    h.showDropdown(dropdown, height);
                }
            }
        });
        item.addEventListener('mouseleave', function(){
            item.classList.remove('-hovered');
            if(hasDropdown){
                if(!h.mobileCheck()){
                    dropdown.classList.remove('-hovered');
                    header.classList.remove('-menu-active');
                    h.hideDropdown(dropdown);
                }
            }
        });
        if(hasDropdown){
            let closeBtn = Util.$('.js-header__dropdown-close-btn', dropdown)[0];
            closeBtn.addEventListener('click', function(){
                if(!h.mobileCheck()){
                    dropdown.classList.remove('-hovered');
                    header.classList.remove('-menu-active');
                    h.hideDropdown(dropdown);
                }
            });
        }
        
    }
    showDropdown(dropdown, height){
        let tween = {height: ''};
        tween.height = height;
        gsap.to(dropdown, tween);
    }
    hideDropdown(dropdown){
        gsap.to(dropdown, {height: 0});
    }
    scrollBehavior(header){
        // ADDING SCROLL DETECTION WITH REQUEST ANIMATION FRAME TO CHILL IT OUT
        let latestKnownScrollY = 0,
            scrollCountStart = 0,
            scrollDist = 0,
            ticking = false;

        const update = () => {
            // reset the tick so we can
            // capture the next onScroll
            ticking = false;
        }

        const onScroll = (ev) => {
            let prevScroll = latestKnownScrollY;
            if(window.scrollY){
                latestKnownScrollY = window.scrollY;
            }else{
                latestKnownScrollY = window.pageYOffset
            }
            if(latestKnownScrollY == 0){
                header.classList.remove('-scrollup');
                header.classList.remove('-scrolled');
                header.classList.remove('-scroll-hide');
                scrollCountStart = 0;
            }else if(prevScroll > latestKnownScrollY){
                header.classList.add('-scrollup');
                scrollDist = scrollDist + (latestKnownScrollY - prevScroll);
                scrollCountStart = scrollDist;
            }else{
                header.classList.add('-scrolled');
                scrollDist = scrollDist + (latestKnownScrollY - prevScroll);
                if(scrollDist > scrollCountStart + 500){
                    header.classList.add('-scroll-hide');
                    header.classList.remove('-scrollup');
                    scrollCountStart = scrollDist;
                }
            }
            requestTick();
        }

        const requestTick = () => {
            if(!ticking) {
                requestAnimationFrame(update);
            }
            ticking = true;
        }

        window.addEventListener('scroll', onScroll, false);
    }
}
export default Header;