import Util from "../utils/util.js";
import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);
class Tips {
    constructor() {
        this.initTips();
    }
    initTips(){
        let swiper = "";
        const mobileCheck = function(){
            if(window.matchMedia('(max-width: 1167px)').matches){
                return true;
            }else{
                return false;
            }
        };
        let isMobile = mobileCheck();

        let tips = Util.$('.js-tips');
        tips.forEach(function(el){
            let tipsCarousel = Util.$('.js-tips__carousel', el);

            if(tipsCarousel.length > 0){
                let slideCount = Util.$('.swiper-slide', tipsCarousel[0]).length;
                if(slideCount > 1 ){
                    let arrowNext = Util.$('.js-carousel-next', el)[0];
                    let arrowPrev = Util.$('.js-carousel-prev', el)[0];
                    arrowNext.addEventListener('click', function(){
                        dataLayer.push({'event': 'tip_slider'});
                    });
                    arrowPrev.addEventListener('click', function(){
                        dataLayer.push({'event': 'tip_slider'});
                    });
                    swiper = new Swiper(tipsCarousel[0], {
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        spaceBetween: 0,
                        simulateTouch: false,
                        allowTouchMove: false,
                        effect: "fade",
                        pagination: {
                            el: '.js-tips__carousel-pagination'
                        },
                        navigation: {
                            nextEl: '.js-carousel-next',
                            prevEl: '.js-carousel-prev',
                        }
                    });
                }
                
            }

        });
        
    }
}
export default Tips;