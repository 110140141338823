import Util from "../utils/util.js";
class VideoWidget {
    constructor() {
        this.initVideoWidget();
    }
    settings = {
        minWidth: {
            small: '476px',
            medium: '768px',
            large: '1168px'
        }
    }
    screenCheck(){
        let screensize = 'xsmall';
        for (const property in this.settings.minWidth) {
            //console.log(`${property}: ${object[property]}`);
            if(window.matchMedia('(min-width:'+ `${this.settings.minWidth[property]}` +')').matches){
                screensize = `${property}`;
            }
        }
        return screensize;
    }
    initVideoWidget(){
        let vw = this;
        const mainSection = Util.$('.js-main-page-content')[0];
        const config = { attributes: true, childList: true, subtree: true };
        const setVids = function(){
            let videoWidgets = Util.$('.js-video-widget');
            
            if(videoWidgets.length > 0){
                videoWidgets.forEach(function(el){
                    let videoWidgetMedia = Util.$('.js-video-widget__media', el)[0];
                    if(videoWidgetMedia){
                        let imgUrl = videoWidgetMedia.getAttribute('data-bg_img_url');
                        let imgSizes = {
                            xsmall : videoWidgetMedia.getAttribute('data-bg_img_xsm'),
                            small : videoWidgetMedia.getAttribute('data-bg_img_sml'),
                            medium : videoWidgetMedia.getAttribute('data-bg_img_med'),
                            large : videoWidgetMedia.getAttribute('data-bg_img_lrg')
                        };
                        let size = vw.screenCheck();
                        let query = imgSizes[size];
                        let bgString = imgUrl + query;
                        videoWidgetMedia.setAttribute("style", "background: url(" + bgString + ") center center no-repeat; background-size: cover" );
                        vw.resizeBehavior(videoWidgetMedia, size, imgUrl, imgSizes);
                    }
                })
            }
        };
        const callback = function(mutationsList, observer) {
            // Use traditional 'for loops' for IE 11
            for(const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    setVids();
                }
                // else if (mutation.type === 'attributes') {
                //     console.log('The ' + mutation.attributeName + ' attribute was modified.');
                // }
            }
        };
        const observer = new MutationObserver(callback);
        
        
        let videoWidgets = Util.$('.js-video-widget');
        if(videoWidgets.length > 0){
            setVids();
        }else{
            observer.observe(mainSection, config);
        }        
    }
    setImg(videoWidgetMedia, size, imgUrl, imgSizes){
        let query = imgSizes[size];
        let bgString = imgUrl + query;
        videoWidgetMedia.setAttribute("style", "background: url(" + bgString + ") center center no-repeat; background-size: cover" );
    }
    
    resizeBehavior(videoWidgetMedia, currentSize, imgUrl, imgSizes){
        // ADDING SCROLL DETECTION WITH REQUEST ANIMATION FRAME TO CHILL IT OUT
        let ticking = false;
        let previousWidth = window.innerWidth;
        let imagesSwitch = false;
        const update = () => {
            // reset the tick so we can
            // capture the next onScroll
            ticking = false;
        }

        const onResize = (ev) => {
            let oldsize = currentSize;
            let newsize = this.screenCheck();
            let newWidth = window.innerWidth;
            // console.log(oldsize);
            // console.log(newsize);
            // console.log(previousWidth);
            // console.log(newWidth);
            if(oldsize !== newsize){
                oldsize == newsize;
                if(previousWidth < newWidth  && !imagesSwitch){
                    this.setImg(videoWidgetMedia, newsize, imgUrl, imgSizes);
                    imagesSwitch = true;
                }
            }
            requestTick();
        }

        const requestTick = () => {
            if(!ticking) {
                requestAnimationFrame(update);
            }
            ticking = true;
        }

        window.addEventListener('resize', onResize, false);
    }

    
}
export default VideoWidget;