import Util from "../utils/util.js";
class HeroSki {
    constructor() {
        this.initHeroSki();
    }
    settings = {
        minWidth: {
            small: '476px',
            medium: '768px',
            large: '1168px'
        }
    }
    screenCheck(){
        let screensize = 'xsmall';
        for (const property in this.settings.minWidth) {
            //console.log(`${property}: ${object[property]}`);
            if(window.matchMedia('(min-width:'+ `${this.settings.minWidth[property]}` +')').matches){
                screensize = `${property}`;
            }
        }
        return screensize;
    }
    initHeroSki(){
        const hero = Util.$('.js-hero-ski')[0];
        const loadingScreen = Util.$('.js-ski-landing__load-screen')[0];
        const body = Util.$('body')[0];
        const video = Util.$('.js-hero-ski__video')[0];
        const videoFallback = Util.$('.js-hero-ski____video-backup')[0];
        const btns = Util.$('.js-hero-ski__filter-btn', hero);
        const loadEvent = new Event('skilandingload');
        if(hero && loadingScreen){
            // console.log('HAVE SKI HERO');
            // window.setTimeout(function(){
            //     loadingScreen.classList.add('-hidden');
            //     body.classList.add('-loaded');
            // }, 5000);
            // window.addEventListener("resize", (event) => {
            //     console.log('resize event');
            // });
            window.addEventListener("load", (event) => {
                loadingScreen.classList.add('-hidden');
                body.classList.add('-loaded');
                window.dispatchEvent(loadEvent);
                //console.log('window load');
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                    //console.log('delayed resize event');
                  }, 500);
              });
            btns.forEach(function(el, i, array){
                let thisItem = i;
                let vidSrc = el.getAttribute('data-vid_src');
                //let vidFallbackStyle = "width: 100%; height: 100%; background-image: url('" + vidSrc + "'); background-size: cover"
                el.addEventListener('click', function(ev){
                    ev.preventDefault();
                    if(el.classList.contains('-active')){
                        return false;
                    }else{
                        el.classList.add('-active');
                        video.classList.add('-switch');
                        
                        videoFallback.remove();
                        video.addEventListener('loadeddata', function() {
                            // Video is loaded and can be played
                            video.classList.remove('-switch');
                        }, false);
                        window.setTimeout(function(){
                            video.src = vidSrc;
                            video.load();
                            }, 1000);
                        array.forEach(function(el, i){
                            if(i != thisItem){
                                el.classList.remove('-active');
                            }
                        });
                    }
                });
            });
        }
    }
}
export default HeroSki;