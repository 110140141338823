import Header from "./global/header.js";
import HeaderMin from "./global/header-min.js";
import Hero from "./components/hero.js";
import HeroSki from "./components/hero-ski.js";
import SnowTracker from "./global/snow-tracker.js";
import HomepageHero from "./components/homepage-hero.js";
import LocalNav from "./global/local-nav.js";
import CardCarousel from "./components/card-carousel.js";
import SortableCardCarousel from "./components/sortable-card-carousel.js";
import ItineraryCarousel from "./components/itinerary-carousel.js";
import ModalWindow from "./components/modal-window.js";
import SnowReport from "./components/snow-report.js";
import VideoWidget from "./components/video-widget.js";
import ImageStripModal from "./components/image-strip-modal.js";
import ScrollTriggered from "./components/scroll-triggered.js";
import TwoColImgParagraph from "./components/two-col-img-paragraph.js";
import HorzScroller from "./components/horz-scroller.js";
import MaskedImage from "./components/masked-image.js";
//import TextBgAnimation from "./components/text-bg-animation.js";
import ImageStripCarousel from "./components/image-strip-carousel.js";
import IGTV from "./components/igtv.js";
import Lightbox from "./components/lightbox.js";
import Tips from "./components/tips.js";

class FeCore {
    constructor() {
        this.initCore();
        
    }
    initCore(){   
        // IE 11 FOREACH POLYFILL
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = function (callback, thisArg) {
                thisArg = thisArg || window;
                for (var i = 0; i < this.length; i++) {
                    callback.call(thisArg, this[i], i, this);
                }
            };
        }
        // IE 11 isNaN POLYFILL     
        Number.isNaN = Number.isNaN || function isNaN(input) {
            return typeof input === 'number' && input !== input;
        }
        const header = new Header;
        const headerMin = new HeaderMin;
        const hero = new Hero;
        const snowTracker = new SnowTracker;
        const heroSki = new HeroSki;
        const homepageHero = new HomepageHero;
        const localNav = new LocalNav;
        const cardCarousel = new CardCarousel;
        const sortableCardCarousel = new SortableCardCarousel;
        const itineraryCarousel = new ItineraryCarousel;
        const modalWindow = new ModalWindow;
        const snowReport = new SnowReport;
        const videoWidget = new VideoWidget;
        const imageStripModal = new ImageStripModal;
        const imageStripCarousel = new ImageStripCarousel;
        const scrollTriggered = new ScrollTriggered;
        const horzScroller = new HorzScroller;
        const maskedImage = new MaskedImage;
        //const textBgAnimation = new TextBgAnimation;
        const twoColImgParagraph = new TwoColImgParagraph;
        const igtv = new IGTV;
        const lightbox = new Lightbox;
        const tips = new Tips;
    }
}
export default FeCore;