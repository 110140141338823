import Util from "../utils/util.js";
import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

Swiper.use([Navigation]);
class ImageStripCarousel {
    constructor() {
        this.initImageStripCarousel();
    }
    initImageStripCarousel(){
        let carouselActive = false;
        let swiper = "";
        const mobileCheck = function(){
            if(window.matchMedia('(max-width: 767px)').matches){
                return true;
            }else{
                return false;
            }
        };
        const carouselNeeded = function(){
            let isMobile = mobileCheck();
            if(isMobile && !carouselActive){
                carouselActive = true;
                swiper = new Swiper('.js-image-strip__group', {
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                    // Responsive breakpoints
                });
                
            }else if(carouselActive && !isMobile){
                swiper.destroy();
                carouselActive = false;
            }
        }
        let imageStrip = Util.$('.js-image-strip');
        let carousel = Util.$('.js-image-strip__group', imageStrip[0]);
        
        if(carousel.length > 0){
            let isMobile = mobileCheck();
            if(isMobile){
                swiper = new Swiper('.js-image-strip__group', {
                    slidesPerView: 'auto',
                    spaceBetween: 0,
                    // Responsive breakpoints
                });
                carouselActive = true;
            }
        }
        
        window.addEventListener('resize', function(){
            Util.debounce(carouselNeeded());
        });

    }
}
export default ImageStripCarousel;