import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollTrigger);
class TwoColImgParagraph {
    constructor() {
        this.initTwoColImgParagraph();
    }
    settings = {
        desktop: '1024px'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initTwoColImgParagraph(){
        const twoColImgParagraphs = Util.$('.js-two-col-img-paragraph');
        twoColImgParagraphs.forEach(function(el){
            el.classList.add('-offscreen');
            ScrollTrigger.create({
                trigger: el,
                start: "top 10%",
                //endTrigger: "#otherID",
                end: "bottom 10%+50px",
                onToggle: (self) =>  el.classList.remove('-offscreen'),
            });
        });
        

       
        
    }
}
export default TwoColImgParagraph;