import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollTrigger);
class Hero {
    constructor() {
        this.initHero();
    }
    settings = {
        minWidth: {
            small: '476px',
            medium: '768px',
            large: '1168px'
        }
    }
    screenCheck(){
        let screensize = 'xsmall';
        for (const property in this.settings.minWidth) {
            //console.log(`${property}: ${object[property]}`);
            if(window.matchMedia('(min-width:'+ `${this.settings.minWidth[property]}` +')').matches){
                screensize = `${property}`;
            }
        }
        return screensize;
    }
    initHero(){
        let hero = Util.$('.js-hero')[0];
        if(hero){
            if(hero.classList.contains('js-hero--vid')){
                const btn = Util.$('.js-hero__media-vid-control-btn', hero)[0];
                const video = Util.$('.js-hero__video', hero)[0];
                const mainBlock = Util.$('.js-main-page-content')[0];
                const heroMedia = Util.$('.js-hero__media', hero)[0];
                mainBlock.classList.add('-has-hero');
                btn.addEventListener('click', function(ev){
                    if(btn.classList.contains('-pause')){
                        btn.classList.remove('-pause');
                        btn.classList.add('-play');
                        video.pause();
                    }else{
                        btn.classList.add('-pause');
                        btn.classList.remove('-play');
                        video.play();
                    }
                });
                let heroHeight = heroMedia.offsetHeight;
                let windowHeight = window.innerHeight;
                if(heroHeight > windowHeight){
                    hero.classList.add('-offscreen');
                }

                ScrollTrigger.create({
                    trigger: heroMedia,
                    start: 'top top+=10px',
                    end: 'bottom bottom',
                    onToggle: (self) => {
                        if(!self.isActive){
                            hero.classList.remove('-offscreen');
                        }else{
                            hero.classList.add('-offscreen');
                        }
                    }
                });

            }else{
                let heroMedia = Util.$('.js-hero__media', hero)[0];
                if(heroMedia){
                    let mainBlock = Util.$('.js-main-page-content')[0];
                    let imgUrl = heroMedia.getAttribute('data-bg_img_url');
                    let imgSizes = {
                        xsmall : heroMedia.getAttribute('data-bg_img_xsm'),
                        small : heroMedia.getAttribute('data-bg_img_sml'),
                        medium : heroMedia.getAttribute('data-bg_img_med'),
                        large : heroMedia.getAttribute('data-bg_img_lrg')
                    };
                    let size = this.screenCheck();
                    let query = imgSizes[size];
                    let bgString = imgUrl + query;
                    mainBlock.classList.add('-has-hero');
                    heroMedia.setAttribute("style", "background: url(" + bgString + ") center center no-repeat; background-size: cover" );
                    this.resizeBehavior(heroMedia, size, imgUrl, imgSizes);
                }
            }
        }        
    }
    setImg(heroMedia, size, imgUrl, imgSizes){
        let query = imgSizes[size];
        let bgString = imgUrl + query;
        heroMedia.setAttribute("style", "background: url(" + bgString + ") center center no-repeat; background-size: cover" );
    }
    
    resizeBehavior(heroMedia, currentSize, imgUrl, imgSizes){
        // ADDING SCROLL DETECTION WITH REQUEST ANIMATION FRAME TO CHILL IT OUT
        let ticking = false;
        let previousWidth = window.innerWidth;
        let imagesSwitch = false;
        const update = () => {
            // reset the tick so we can
            // capture the next onScroll
            ticking = false;
        }

        const onResize = (ev) => {
            let oldsize = currentSize;
            let newsize = this.screenCheck();
            let newWidth = window.innerWidth;
            // console.log(oldsize);
            // console.log(newsize);
            // console.log(previousWidth);
            // console.log(newWidth);
            if(oldsize !== newsize){
                oldsize == newsize;
                if(previousWidth < newWidth  && !imagesSwitch){
                    this.setImg(heroMedia, newsize, imgUrl, imgSizes);
                    imagesSwitch = true;
                }
            }
            requestTick();
        }

        const requestTick = () => {
            if(!ticking) {
                requestAnimationFrame(update);
            }
            ticking = true;
        }

        window.addEventListener('resize', onResize, false);
    }
}
export default Hero;