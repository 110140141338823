import Util from "../utils/util.js";
import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);
class SnowReport {
    constructor() {
        this.initSnowReport();
    }
    initSnowReport(){
        let carouselActive = false;
        let swiper = "";
        const mobileCheck = function(){
            if(window.matchMedia('(max-width: 767px)').matches){
                return true;
            }else{
                return false;
            }
        };
        const carouselNeeded = function(){
            let isMobile = mobileCheck();
            if(isMobile && !carouselActive){
                carouselActive = true;
                swiper = new Swiper('.js-snow-report__group', {
                    //slidesPerView: 'auto',
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 0,
                    // Responsive breakpoints
                    pagination: {
                        el: '.js-snow-report__pagination',
                    },
                });
                
            }else if(carouselActive && !isMobile){
                swiper.destroy();
                carouselActive = false;
            }
        }
        let snowReport = Util.$('.js-snow-report');
        let carousel = Util.$('.js-snow-report__group', snowReport[0]);
        
        if(carousel.length > 0){
            let isMobile = mobileCheck();
            if(isMobile){
                swiper = new Swiper('.js-snow-report__group', {
                    //slidesPerView: 'auto',
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 0,
                    // Responsive breakpoints
                    pagination: {
                        el: '.js-snow-report__pagination',
                    },
                });
                carouselActive = true;
            }
        }
        
        window.addEventListener('resize', function(){
            Util.debounce(carouselNeeded());
        });

    }
}
export default SnowReport;