import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollTrigger);
class SnowTracker {
    constructor() {
        this.initSnowTracker();
    }
    settings = {
        desktop: '1024px'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initSnowTracker(){
        const tracker = Util.$('.js-snow-tracker')[0];        
        const mainContent = document.getElementById("maincontent");

        if(tracker && !mainContent){
            const trackerBtn = Util.$('.js-snow-tracker__link', tracker)[0];
            const trackerDrawer = Util.$('.js-snow-tracker-drawer')[0];
            const dimissBtn = Util.$('.js-snow-tracker-drawer__dimiss', trackerDrawer)[0];
            const smallTrackerBtn = Util.$('.js-snow-tracker__small-btn')[0];
            const bottomContent = Util.$('.js-ski-landing__lower')[0];
            const topContent = Util.$('.js-ski-landing__upper')[0];
            const snowTrackerGraphic = Util.$('.js-snow-tracker__graphic-svg')[0];
            const snowTrackerNum = Util.$('.js-snow-tracker__val')[0];
            const scaleVolume = tracker.getAttribute('data-snow_total');
            const hero = Util.$('.js-hero-ski');
            let value = 0;
            if(trackerBtn){
                trackerBtn.addEventListener('click', function(ev){
                    ev.preventDefault();
                    if(trackerDrawer.classList.contains('-visible')){
                        trackerDrawer.classList.remove('-visible');
                        trackerDrawer.classList.remove('-over');
                    }else{
                        trackerDrawer.classList.add('-visible');
                        setTimeout(() => {
                            trackerDrawer.classList.add('-over');
                          }, 500);
                    }
                });
            }
            if(smallTrackerBtn){
                smallTrackerBtn.addEventListener('click', function(ev){
                    ev.preventDefault();
                    if(trackerDrawer.classList.contains('-visible')){
                        trackerDrawer.classList.remove('-visible');
                    }else{
                        trackerDrawer.classList.add('-visible');
                    }
                });
            }
            if(dimissBtn){
                dimissBtn.addEventListener('click', function(ev){
                    ev.preventDefault();
                    trackerDrawer.classList.remove('-visible');
                    trackerDrawer.classList.remove('-over');
                });
            }
            // if(bottomContent && smallTrackerBtn){
            //     ScrollTrigger.create({
            //         trigger: bottomContent,
            //         start: "top 50%",
            //         //endTrigger: "#otherID",
            //         end: "bottom 50%+=100px",
            //         onToggle: (self) => smallTrackerBtn.classList.add('-active'),
            //         onLeave: (self) => smallTrackerBtn.classList.remove('-active'),
            //         onLeaveBack: (self) => smallTrackerBtn.classList.remove('-active'),
            //     });
            // }
            // gsap.to(snowTrackerGraphic, {
            //     scrollTrigger: {
            //         trigger: hero,
            //         start: "bottom bottom", 
            //         endTrigger: topContent,
            //         end: "bottom bottom",
            //         pin: tracker,
            //         pinSpacing: false,
            //         scrub: false,
            //         onToggle: (self) => {
            //             topContent.classList.add('-tracking');
            //         },
            //         onLeave: (self) => {
            //             topContent.classList.remove('-tracking');
            //         }
                    
            //     }, // start the animation when ".box" enters the viewport (once)
            //     scaleY: 1,
            //     duration: 10,
            //     delay: 1,
            //     onUpdate: (self) => {
            //         let thisIncrement = Math.round(scaleVolume * (value / 500));
            //         let thisUpdate;
            //         if(scaleVolume > thisIncrement){
            //             thisUpdate = thisIncrement;
            //         }else{
            //             thisUpdate = scaleVolume;
            //         }
            //         snowTrackerNum.textContent = thisUpdate;
            //         value++;
            //     },
            //   });
            //this.scrollBehavior(header[0]);
            //this.bindMenuToggle(mainNav[0], mobileButton[0], header[0], true);
            // dropdowns.forEach( (el) => {
            //     let dropdown = Util.$('.js-header__main-nav-dropdown', el);
            //     if(dropdown.length > 0){
            //         this.bindDropdown(dropdown[0], el, true, header[0]);
            //         el.classList.add('-has-dropdown');
            //         let mobileToggle = Util.$('.js-header__main-nav-item-mobile-toggle', el);
            //         if(mobileToggle.length > 0){
            //             this.bindMenuToggle(dropdown[0], mobileToggle[0], el, false);
            //         }
            //     }else{
            //         this.bindDropdown(dropdown[0], el, false);
            //     }
            // });
        }
        
    }
}
export default SnowTracker;