import Util from "../utils/util.js";
import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);
class CardCarousel {
    constructor() {
        this.initCardCarousel();
    }
    initCardCarousel(){
        let carousel = Util.$('.js-swiper-container');
        if(carousel.length > 0){
            carousel.forEach(function(el){
                let cardMarkup = document.createElement('li');
                let slides = Util.$('.swiper-slide', el);
                let count = slides.length;
                let classCount = "-slide-count-" + count;
                el.classList.add(classCount);
                cardMarkup.classList.add('swiper-slide');
                let list = Util.$('.swiper-wrapper', el);
                list[0].appendChild(cardMarkup);
            });
            
            const swiper = new Swiper('.js-swiper-container', {
                //slidesPerView: 'auto',
                slidesPerView: 1.15,
                slidesPerGroup: 1,
                spaceBetween: 23,
                // Responsive breakpoints
                breakpoints: {
                    // when window width is >= 320px
                    476: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                    },
                    // when window width is >= 480px
                    768: {
                        slidesPerView: 3,
                        slidesPerGroup: 2
                    },
                    // when window width is >= 480px
                    1068: {
                        slidesPerView: 4.25,
                        slidesPerGroup: 3
                    },
                    // when window width is >= 1480px
                    1480: {
                        slidesPerView: 4.87,
                        slidesPerGroup: 5
                    },
                    1680: {
                        slidesPerView: 4.87,
                        slidesPerGroup: 5
                    }
                },
                navigation: {
                    nextEl: '.js-carousel-next',
                    prevEl: '.js-carousel-prev',
                },
            });
            // new Glide('.glide',{
            //     type: 'carousel',
            //     bound: 'true',
            //     gap: 20,
            //     startAt: 0,
            //     perView: 4,
            //     focusAt: 0,
            //     peek: 150,
            //     breakpoints: {
            //         1450: {
            //             gap: 20,
            //             perView: 3,
            //             peek: 150
            //         },
            //         900: {
            //             gap: 20,
            //             perView: 2,
            //             peek: 100
            //         },
            //         450: {
            //             perView: 1,
            //             peek: 50
            //         }
            //     }
            // }).mount();
        }
    }
}
export default CardCarousel;