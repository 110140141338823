import { gsap } from "gsap/gsap-core";
import { CSSPlugin } from 'gsap/CSSPlugin';
import Util from "../utils/util.js";
gsap.registerPlugin(CSSPlugin);
class LocalNav {
    constructor() {
        this.initLocalNav();
    }
    settings = {
        desktop: '1168px'
    }
    mobileCheck(){
        if(window.matchMedia('(min-width:'+ this.settings.desktop +')').matches){
            return false;
        }else{
            return true;
        }
    }
    initLocalNav(){
        let localNav = Util.$('.js-local-nav')[0];
        let toggleButton = Util.$('.js-local-nav__toggle', localNav)[0];
        let dropdown = Util.$('.js-local-nav__dropdown' , localNav)[0];
        let dropdownList = Util.$('.js-local-nav__list', localNav)[0];
        if(localNav){
            this.bindNavToggle(localNav, toggleButton, dropdown, dropdownList);
        }
        
        
    }
    bindNavToggle(localNav, toggleButton, dropdown, dropdownList){
        // FOR ALL MENU TOGGLE BUTTONS - TOGGLE CLASS ON ITEM AND DROPDOWN
        let ln = this;
        toggleButton.addEventListener('click', function(ev){
            ev.preventDefault();
            if(ln.mobileCheck()){
                let active = toggleButton.classList.contains('-active');
                let height = dropdownList.offsetHeight;
                if(active){
                    toggleButton.classList.remove('-active');
                    ln.hideDropdown(dropdown);
                }else{
                    toggleButton.classList.add('-active');
                    ln.showDropdown(dropdown, height);                
                }
            }
        });
    }
    showDropdown(dropdown, height){
        let tween = {height: ''};
        tween.height = height;
        gsap.to(dropdown, tween);
    }
    hideDropdown(dropdown){
        gsap.to(dropdown, {height: 0});
    }
}
export default LocalNav;