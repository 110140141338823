import Util from "../utils/util.js";
import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);
class ItineraryCarousel {
    constructor() {
        this.initItineraryCarousel();
    }
    initItineraryCarousel(){
        let carousel = Util.$('.js-itinerary-card-group__slider');
        if(carousel.length > 0){
            const swiper = new Swiper('.js-itinerary-card-group__slider', {
                //slidesPerView: 'auto',
                slidesPerView: 1,
                slidesPerGroup: 1,
                spaceBetween: 0,
                // Responsive breakpoints
                navigation: {
                    nextEl: '.js-carousel-next',
                    prevEl: '.js-carousel-prev',
                },
                pagination: {
                    el: '.js-itinerary-card-group__pagination',
                },
            });
        }
    }
}
export default ItineraryCarousel;